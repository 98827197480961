// Variables
@DX1gray: #434345;
@BSblue: #428bca;
@border: 1px solid #ccc;
@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;
@screen-xs-max: (@screen-sm - 1);
@screen-sm-max: (@screen-md - 1);

//html { font-size: 14px!important; }
//.Normal { font-size: 1rem!important; }

#DX1Testimonials {
    .testimonial-header {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 3px solid @DX1gray;
    }

    .testimonial-list {
        list-style: none;

        .testimonial {
            border-left-color: @BSblue;
            padding: 5px 15px;
            margin-bottom: 15px;
        }

        .testimonial-text {
            font-size: 1rem;
            word-wrap: break-word;
        }

        .testimonial-rating {
            overflow: hidden;
            font-size: 1rem;

            .rating-title {
                float: left;
                margin-right: 10px;
            }
        }

        .testimonal-signature {
            font-weight: 700;
            font-size: .9rem;
        }
    }

    .listing-controls-bottom {
        padding-top: 10px;
        border-top: 3px solid @DX1gray;
        overflow: hidden;

        @media(min-width: 640px) {
            display: flex;
            align-items: center;
        }

        .counter {
            padding-left: 0;
            text-align: left;

            @media(max-width:639px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .page-switch {
            text-align: right;
            padding-right: 0;
            padding-left: 0;

            @media(max-width:639px) {
                width: 100%;
                text-align: left;
            }

            select.sort-list {
                display: block;
                width: 100%;
                margin: 0 3px 6px 0;
                float: none;

                @media (min-width: @screen-xs) {
                    display: inline-block;
                    width: 60%;
                }

                @media (min-width: @screen-sm) {
                    width: 225px;
                }
            }

            select.page-list {
                width: auto;
                min-width: 6em;
                vertical-align: top;
                margin: 0 2px 0 0;
            }
        }

        .page-link {
            span {
                display: inline-block;

                &.btn-icon {
                    width: 42px;
                    text-align: center;
                    font-size: 1.25em;
                }

                &.btn-text {
                    position: relative;
                    top: -1px;
                }
            }
        }

        .form-control {
            width: auto;
            display: inline-block;
        }
    }


    .dx1-rating {
        float: left;

        i {
            font-size: 22px;
            padding: 0 1px;
        }
    }
}

.list-no-content {
    color: #434345;
    background: #f7dad9;
    padding: 17px 18px;
    border: 1px solid #eeb1af;
    border-radius: 4px;
}